import { useMedia } from 'react-use'
import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Button, Link } from '~/components'
import { Image } from '~/components/Image'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { FeatureHeroBannerProps } from '~/sections/HeroBanner/HeroBanner.types'
import HeroBannerBackgroundImage from '~/sections/HeroBanner/HeroBannerBackgroundImage'
import { BannerButtonSizeClassMap } from '../../components/Button/Button.types'
import styles from './HeroBanner.module.css'

export const FeatureHeroBanner = ({
  background,
  action,
  actionRight,
  textBackground,
  align,
  textBackgroundColor,
  isCurvedImage,
  skus,
  bannerLogoLeft,
  bannerLogoRight,
  children,
}: FeatureHeroBannerProps) => {
  const CARD_WIDTH = 'md:w-1/2 w-full'
  const BTN_CLASS = 'flex w-full px-4'
  const isTwoBannerLogo = !!bannerLogoRight?.logoImage?.src
  const isMobile = useMedia('(max-width: 767px)', false)
  const bannerLinkUrl = action?.isDisabled ? null : action?.href

  const renderButtonHandler = (action) => {
    return (
      <Button
        status='primary'
        size={action.size}
        theme={{
          root: styles.button,
        }}
        style={{
          backgroundColor: action?.bgColor,
        }}>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegularBold}>
          <span
            className={classNames(BannerButtonSizeClassMap[action.size])}
            style={{
              color: action?.textColor,
            }}>
            {action?.title}
          </span>
        </Typography>
        <VisuallyHidden>{action?.accessibleTitle}</VisuallyHidden>
      </Button>
    )
  }

  return (
    <div className={styles.wrapper}>
      <HeroBannerBackgroundImage
        {...background}
        isCurvedImage={isCurvedImage}
        renderLinks={!bannerLinkUrl}
      />
      <div
        className='absolute flex h-full items-center flex-wrap md:flex-nowrap w-full'
        style={{ top: 0 }}>
        {bannerLogoLeft?.logoImage?.src ? (
          <div
            className={classNames(
              'order-last md:order-first',
              isTwoBannerLogo ? CARD_WIDTH : 'w-full',
              {
                hidden: isMobile && bannerLogoLeft?.hideInMobile,
              }
            )}>
            <div
              className={classNames('flex flex-col rounded-md items-baseline')}>
              {bannerLogoLeft?.logoImage?.src && (
                <div
                  className={classNames('flex w-full justify-center', {
                    'lg:!justify-start':
                      bannerLogoLeft.logoPlacement === 'left',
                    'lg:!justify-end': bannerLogoLeft.logoPlacement === 'right',
                    'lg:!justify-center':
                      bannerLogoLeft.logoPlacement === 'center',
                  })}>
                  <Image
                    src={bannerLogoLeft.logoImage.src}
                    alt={bannerLogoLeft.logoImage.altText}
                    width={bannerLogoLeft.logoImage.width}
                    height={bannerLogoLeft.logoImage.height}
                    className='max-h-[300px] lg:max-h-[650px]'
                    priority
                    style={{
                      objectFit: 'contain',
                      height: bannerLogoLeft.logoImage.height,
                    }}
                  />
                </div>
              )}
              {action != null && (
                <div
                  className={classNames(BTN_CLASS, {
                    'justify-start': bannerLogoLeft.logoPlacement === 'left',
                    'justify-end': bannerLogoLeft.logoPlacement === 'right',
                    'justify-center': bannerLogoLeft.logoPlacement === 'center',
                    hidden: isMobile && action?.hideInMobile,
                  })}>
                  {renderButtonHandler(action)}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'order-last md:order-first',
              isTwoBannerLogo ? CARD_WIDTH : 'w-full'
            )}>
            <div
              className={classNames('flex flex-col rounded-md items-baseline')}>
              {children}
              {action != null && (
                <div
                  className={classNames(BTN_CLASS, {
                    'justify-start': bannerLogoLeft.logoPlacement === 'left',
                    'justify-end': bannerLogoLeft.logoPlacement === 'right',
                    'justify-center': bannerLogoLeft.logoPlacement === 'center',
                  })}>
                  {renderButtonHandler(action)}
                </div>
              )}
            </div>
          </div>
        )}

        {bannerLogoRight?.logoImage?.src && (
          <div
            className={classNames(CARD_WIDTH, {
              hidden: isMobile && bannerLogoRight?.hideInMobile,
            })}>
            <div
              className={classNames(
                'flex flex-col lg:gap-10 rounded-md items-baseline'
              )}>
              {bannerLogoRight?.logoImage?.src && (
                <div
                  className={classNames('flex w-full justify-center', {
                    'lg:!justify-start':
                      bannerLogoRight.logoPlacement === 'left',
                    'lg:!justify-end':
                      bannerLogoRight.logoPlacement === 'right',
                    'lg:!justify-center':
                      bannerLogoRight.logoPlacement === 'center',
                  })}>
                  <Image
                    src={bannerLogoRight.logoImage.src}
                    alt={bannerLogoRight.logoImage.altText}
                    width={bannerLogoRight.logoImage.width}
                    height={bannerLogoRight.logoImage.height}
                    className='max-h-[300px] lg:max-h-[650px]'
                    priority
                    style={{
                      objectFit: 'contain',
                      height: bannerLogoRight.logoImage.height,
                    }}
                  />
                </div>
              )}
              {actionRight != null && (
                <div
                  className={classNames(BTN_CLASS, {
                    'justify-start': bannerLogoRight.logoPlacement === 'left',
                    'justify-end': bannerLogoRight.logoPlacement === 'right',
                    'justify-center':
                      bannerLogoRight.logoPlacement === 'center',
                    hidden: isMobile && actionRight?.hideInMobile,
                  })}>
                  {renderButtonHandler(actionRight)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!!skus?.length && (
        <div
          className={classNames(
            'flex absolute gap-2 z-30',
            styles.skuContainer
          )}>
          {skus.map((productSku) => {
            return (
              <div key={productSku?.sku}>
                <Link
                  href={productSku.url?.href}
                  title={productSku.url?.title || productSku.title}>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodySmall}
                    className={classNames('text-white', styles.sku)}>
                    {productSku?.sku}
                  </Typography>
                  <VisuallyHidden>{productSku?.title}</VisuallyHidden>
                </Link>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
